import { environment } from '@/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IManager } from '../auth/manager/types/manager.type';
import { lastValueFrom } from 'rxjs';

interface ResApi {
  code: boolean;
  result: any;
  message: string;
}

type obj = {
  [key: string]: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class APIService {
  private baseUrl = environment.baseURL;
  // private baseUrl = localStorage.getItem('BASEURL');

  spinnerSig = signal<boolean>(false);

  managerDataSig = signal<IManager | null>(null);

  private params: any = {};

  private dateFormat = 'd MMM y';
  private dateTimeFormat = 'd MMM y h:mm a';

  capitalize = (s: string) =>
    s
      .toLowerCase()
      .split(' ')
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
      .join(' ');

  constructor(
    private http: HttpClient,
    private notification: NzMessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        this.params = this.queryParams();
        this.params.path = val.url;
      }
    });
  }

  public get getParams() {
    return this.params;
  }

  public get getDateFormat(): string {
    return this.dateFormat;
  }

  public get getDateTimeFormat(): string {
    return this.dateTimeFormat;
  }

  updateManagerSignal(manager: IManager) {
    this.managerDataSig.set(manager);
  }

  queryParams() {
    const keys = this.route.snapshot.queryParamMap.keys;
    const obj: obj = {};

    keys.forEach((e: string) => {
      obj[e] = this.route.snapshot.queryParamMap.get(e);
    });

    return obj;
  }

  openNotification(message: string, type = 'success'): void {
    this.notification.create(type, this.capitalize(message));
  }
  openNotificationNoMod(message: string, type = 'success'): void {
    this.notification.create(type, message);
  }

  public get loggedIn(): boolean {
    return localStorage.getItem('tender-truck-token') !== null;
  }

  fileUpload(payload: any) {
    const formData = new FormData();
    formData.append('file', payload);
    return this.http.post<ResApi>(`${this.baseUrl}/file/upload`, payload);
  }

  login(payload: object) {
    return this.http.post<ResApi>(`${this.baseUrl}/manager/login`, payload);
  }

  tokenData(token: string) {
    return this.http.get<ResApi>(`${this.baseUrl}/manager/token-data`, {
      params: {
        token,
      },
    });
  }

  getManager(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/manager/`, { params: params });
  }

  createManager(payload: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/manager/create-manager`, payload);
  }
  deleteManager(managerId: string, isActive: boolean) {
    const params = { isActive };
    return this.http.post<ResApi>(`${this.baseUrl}/manager/delete/${managerId}`, null, { params });
  }

  changePassword(payload: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/manager/change-password`, payload);
  }
  confirmPassword(payload: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/manager/confirm-password`, payload);
  }

  getUsers(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/user/`, { params: params });
  }

  async profile() {
    const data = await lastValueFrom(this.http.get<ResApi>(`${this.baseUrl}/manager/profile`));

    const manager = data.result as IManager;
    if (manager && manager.isActive) {
      this.managerDataSig.set(manager);
    } else {
      this.logout();
    }
  }

  getMessages(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/message/get-messages`, { params: params });
  }

  getFlow() {
    return this.http.get<ResApi>(`${this.baseUrl}/content/`);
  }

  getGraphData(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/message/get-graph-data`, { params: params });
  }

  getUserGraphData() {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-users-graph-data`);
  }
  getOrderGraphData() {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-orders-graph-data`);
  }

  getOnboardingGraphData() {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-registration-graph-data`);
  }
  getKYCGraphData() {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-kyc-graph-data`);
  }

  refreshUser(data: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/user/refresh-user`, data);
  }

  getTemplateMessages(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/message/get-template-graph-data`, { params: params });
  }

  getTemplateMessagesGraphData() {
    return this.http.get<ResApi>(`${this.baseUrl}/message/get-messages-graph-data-template`);
  }

  sendMarketingMessage(data: any) {
    const header = {
      'api-key': '7b2a7d9c1fda4f7a9f3c1a7f7f1e3d3c-4d7f3a9c-3e12c0d9',
    };
    return this.http.post<ResApi>(`${this.baseUrl}/order/send-template`, data, { headers: header });
  }

  getOnboardingUserData(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-onboarding-graph-data`, { params: params });
  }

  getKycUserData(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-kyc-data`, { params: params });
  }

  getOrdersData(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-orders-data`, { params: params });
  }

  getOrderStatistics(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-order-stats`, { params: params });
  }

  getOrderTrackingIds(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-orders-trackingId`, { params: params });
  }
  getOrderByID(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-orders-by-id`, { params: params });
  }

  getVerifiedUsers(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/verifiedUser/get-all-verified-user`, { params: params });
  }

  getVerifiedUsersStats() {
    return this.http.get<ResApi>(`${this.baseUrl}/verifiedUser/get-verified-users-stats`);
  }

  addToVerifiedUsers(data: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/verifiedUser/save-user`, data);
  }

  removeFromVerifiedUsers(data: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/verifiedUser/removeVerifiedUser`, data);
  }

  deleteFromStoppedServices(data: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/stoppedServices/delete`, data);
  }

  getStoppedServicesUsers(data: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/stoppedServices`, { params: data });
  }

  getInActiveUsers(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-inactive-users`, { params: params });
  }

  getConfirmedAndBidedOrders(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-orders-confirm-bided-data`, { params: params });
  }

  getOrdersMobile(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/order/get-orders-mobile`, { params: params });
  }

  getUserTruckErrorData(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-users-truck-error`, { params: params });
  }

  getDroppedUsers(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/user/get-dropped-users`, { params: params });
  }

  sendMessage(data: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/message/send-message-whatsapp`, data);
  }

  getSalesTeamUsers(params: any) {
    return this.http.get<ResApi>(`${this.baseUrl}/salesTeam/`, { params: params });
  }

  createSalesTeamUser(data: any) {
    return this.http.post<ResApi>(`${this.baseUrl}/salesTeam/create`, data);
  }

  removeSalesTeamUser(salesTeamUserID: string, isActive: boolean = false) {
    const params = { isActive };
    return this.http.post<ResApi>(`${this.baseUrl}/salesTeam/delete/${salesTeamUserID}`, null, { params: params });
  }

  logout() {
    localStorage.removeItem('tender-truck-token');
    this.router.navigate(['/login']);
  }
}
